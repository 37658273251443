const getters = {
    condition: state => state.filter.condition,
    showLogin: state => state.user.showLogin,
    userinfo: state => state.user.userinfo,
    access_token: state => state.user.access_token,
    refresh_token: state => state.user.refresh_token,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    menu: state => state.user.menu,
}
export default getters