import {getStore} from "@/utils/store";

const state = {
    // condition:{t:1, q:"", days:-1, siteFlag:-1}
    condition: getStore({
        name: 'condition'
    }) || {t:1, q:"", days:-1, siteFlag:-1},
}
const mutations = {
    SET_CONDITION_T:(state,value)=>{
        state.condition.t = value;
    },
    SET_CONDITION_Q:(state,value)=>{
        state.condition.q = value;
    },
    SET_CONDITION_DAYS:(state,value)=>{
        state.condition.days = value;
    },
    SET_CONDITION_SITE_FLAG:(state,value)=>{
        state.condition.siteFlag = value;
    }
}
const actions = {
    set_condition_t:(context, condition)=>{
        context.commit("SET_CONDITION_T", condition)
    },
    set_condition_q:(context, condition)=>{
        context.commit("SET_CONDITION_Q", condition)
    },
    set_condition_days:(context, condition)=>{
        context.commit("SET_CONDITION_DAYS", condition)
    },
    set_condition_siteFlag:(context, condition)=>{
        context.commit("SET_CONDITION_SITE_FLAG", condition)
    }
}

export default{
    state,
    mutations,
    actions
}