import { getStore, setStore } from '@/utils/store'
import { loginBySocial, getUserInfo, logout, refreshToken } from '@/api/login'

const state = {
    showLogin: false,
    userInfo: getStore({
        name: 'userInfo'
    }) || {},
    permissions: getStore({
        name: 'permissions'
    }) || [],
    roles: [],
    menu: getStore({
        name: 'menu'
    }) || [],
    menuAll: [],
    access_token: getStore({
        name: 'access_token'
    }) || '',
    refresh_token: getStore({
        name: 'refresh_token'
    }) || ''
}
const mutations = {
    SET_SHOW_LOGIN: (state, isShow) => {
        state.showLogin = isShow
    },
    SET_ACCESS_TOKEN: (state, access_token) => {
        state.access_token = access_token
        setStore({
            name: 'access_token',
            content: state.access_token,
            type: 'session'
        })
    },
    SET_REFRESH_TOKEN: (state, rfToken) => {
        state.refresh_token = rfToken
        setStore({
            name: 'refresh_token',
            content: state.refresh_token,
            type: 'session'
        })
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
        setStore({
            name: 'userInfo',
            content: userInfo,
            type: 'session'
        })
    },
    SET_MENU: (state, params = {}) => {
        const { menu, type } = params
        if (type !== false) state.menu = menu
        setStore({
            name: 'menu',
            content: menu,
            type: 'session'
        })
    },
    SET_MENU_ALL: (state, menuAll) => {
        state.menuAll = menuAll
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
        const list = {}
        for (let i = 0; i < permissions.length; i++) {
            list[permissions[i]] = true
        }

        state.permissions = list
        setStore({
            name: 'permissions',
            content: list,
            type: 'session'
        })
    }
}
const actions = {
    // 根据OpenId登录
    LoginBySocial({ commit }, param) {
        return new Promise((resolve, reject) => {
            loginBySocial(param.state, param.code).then(response => {
                const data = response.data
                commit('SET_ACCESS_TOKEN', data.access_token)
                commit('SET_REFRESH_TOKEN', data.refresh_token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 刷新token
    RefreshToken({ commit, state }) {
        return new Promise((resolve, reject) => {
            refreshToken(state.refresh_token).then(response => {
                const data = response.data
                commit('SET_ACCESS_TOKEN', data.access_token)
                commit('SET_REFRESH_TOKEN', data.refresh_token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 查询用户信息
    GetUserInfo({ commit }) {
        return new Promise((resolve, reject) => {
            getUserInfo().then((res) => {
                const data = res.data.data || {}
                commit('SET_USER_INFO', data.appUser)
                commit('SET_ROLES', data.roles || [])
                commit('SET_PERMISSIONS', data.permissions || [])
                resolve(data)
            }).catch(() => {
                reject()
            })
        })
    },
    // 登出
    LogOut({ commit }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                // resetRouter()
                commit('SET_MENU', [])
                commit('SET_PERMISSIONS', [])
                commit('SET_USER_INFO', {})
                commit('SET_ACCESS_TOKEN', '')
                commit('SET_REFRESH_TOKEN', '')
                commit('SET_ROLES', [])
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 注销session
    FedLogOut({ commit }) {
        return new Promise(resolve => {
            // resetRouter()
            commit('SET_MENU', [])
            commit('SET_PERMISSIONS', [])
            commit('SET_USER_INFO', {})
            commit('SET_ACCESS_TOKEN', '')
            commit('SET_REFRESH_TOKEN', '')
            commit('SET_ROLES', [])
            commit('DEL_ALL_TAG')
            resolve()
        })
    },
    // 获取用户菜单
    // GetMenu({ commit }, obj) {
    //     // 记录用户点击顶部信息，保证刷新的时候不丢失
    //     commit('LIKE_TOP_MENUID', obj)
    //
    //     return new Promise(resolve => {
    //         getMenu(obj.id).then((res) => {
    //             const data = res.data.data
    //             const menu = deepClone(data)
    //             menu.forEach(ele => {
    //                 addPath(ele)
    //             })
    //             const type = obj.type
    //             commit('SET_MENU', { type, menu })
    //             resolve(menu)
    //         })
    //     })
    // },
}

export default{
    state,
    mutations,
    actions
}