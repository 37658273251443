/* eslint-disable no-undef */
export function sendMessageToChromePlugin(message, callback = () => {}) {
    // console.log("send")
    try {
        // 向指定id插件的 backgrond.js 发送消息 pebkhooddblfngkkokfidkobojjllkdp
        chrome.runtime.sendMessage('pebkhooddblfngkkokfidkobojjllkdp', {
            message: message
        }, res => {
            callback(res)
        })
    } catch (error) {
        callback("error")
    }
}